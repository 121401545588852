import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { db, COLLECTIONS } from "../services/firebase-init";
import type { UserSettings } from "../types/firebase";

export function useUserSettings() {
  const { user } = useUser();
  const devUserId = import.meta.env.DEV ? localStorage.getItem('dev_user_id') : null;
  const effectiveUserId = devUserId || user?.id;

  return useQuery({
    queryKey: ["userSettings", effectiveUserId],
    queryFn: async () => {
      if (!effectiveUserId) return null;
      
      const docRef = doc(db, COLLECTIONS.USER_SETTINGS, effectiveUserId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        return null;
      }
      
      return {
        id: docSnap.id,
        ...docSnap.data()
      };
    },
    enabled: !!effectiveUserId
  });
} 
import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://b159a680fbde94e28a5815d90970ff75@o4508434903007232.ingest.us.sentry.io/4508434904907776",
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: [
          'localhost',
          'app.ukra.pro',
          /^https:\/\/lw-management\.web\.app/,
          /^https:\/\/lw-management\.firebaseapp\.com/,
          /^https:\/\/app\.ukra\.pro/,
          /^https:\/\/.*\.ukra\.pro/,
        ],
        // Exclude Clerk domains from tracing
        shouldCreateSpanForRequest: (url) => {
          return !url.includes('clerk.ukra.pro');
        },
      }),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        showBranding: false,
        buttonLabel: 'Report Issue',
        submitButtonLabel: 'Send Feedback',
        messageLabel: 'What happened?',
        emailLabel: 'Your email (optional)',
        nameLabel: 'Your name (optional)',
        showEmail: true,
        showName: true,
        enableScreenshot: true,
      }),
    ],
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_VERSION || 'development',
    
    // Performance
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.2,
    
    // Session Replay
    replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Ignore Clerk errors
    ignoreErrors: [
      // Network errors
      'Network request failed',
      'Failed to fetch',
      // Browser extensions
      /^chrome-extension:\/\//,
      /^moz-extension:\/\//,
      // Third-party scripts
      'Script error',
      // React dev tools
      'ResizeObserver loop limit exceeded',
      // Clerk errors
      /ClerkJS: Network error/,
      /clerk\.ukra\.pro/,
    ],

    beforeSend(event, hint) {
      // Don't send Clerk-related errors
      if (event.request?.url?.includes('clerk.ukra.pro')) {
        return null;
      }

      // Add user context if available
      const user = localStorage.getItem('clerk-user');
      if (user) {
        try {
          const userData = JSON.parse(user);
          event.user = {
            id: userData.id,
            email: userData.email,
            username: userData.username
          };
        } catch (e) {
          // Ignore parsing errors
        }
      }

      // Add environment context
      event.tags = {
        ...event.tags,
        isDev: import.meta.env.DEV,
        version: import.meta.env.VITE_APP_VERSION,
      };

      return event;
    },

    // Debug in development
    debug: import.meta.env.DEV,
    
    // Add more context in development
    attachStacktrace: true,
    normalizeDepth: import.meta.env.DEV ? 10 : 3,
    tracePropagationTargets: [
      // Only trace our own domains
      'app.ukra.pro',
      'localhost',
    ],
  });
}

// Helper function to manually capture feedback
export function captureFeedback(feedback: {
  name?: string;
  email?: string;
  message: string;
  screenshot?: string;
}) {
  const eventId = Sentry.captureMessage("User Feedback");

  return Sentry.captureFeedback({
    name: feedback.name,
    email: feedback.email,
    message: feedback.message,
    associatedEventId: eventId,
  }, {
    attachments: feedback.screenshot ? [{
      filename: 'screenshot.png',
      data: feedback.screenshot,
    }] : undefined,
    captureContext: {
      tags: {
        source: 'manual_feedback',
        userInitiated: true,
      },
    },
  });
} 
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase-init';
import type { Player } from '../types';

export async function getLeadership() {
  const q = query(
    collection(db, COLLECTIONS.PLAYERS),
    where('allianceRank', 'in', ['R4', 'R5'])
  );
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as Player[];
} 
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ProtectedRoute } from "./components/ProtectedRoute";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import * as Sentry from "@sentry/react";
import { FeedbackButton } from './components/FeedbackButton/FeedbackButton';
import { useUser } from '@clerk/clerk-react';
import { AdminPanel } from './components/AdminPanel/AdminPanel';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function ErrorFallback({ error }: { error: Error }) {
  const { user } = useUser();

  useEffect(() => {
    if (error && user) {
      Sentry.showReportDialog({
        user: {
          name: user.fullName || undefined,
          email: user.primaryEmailAddress?.emailAddress
        }
      });
    }
  }, [error, user]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Something went wrong</h2>
        <p className="text-gray-400 mb-4">Our team has been notified and is working on a fix.</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary 
          fallback={ErrorFallback}
          showDialog={false}
        >
          <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
            <ProtectedRoute>
              <SentryRoutes>
                <Route path="/" element={<Home />} />
              </SentryRoutes>
            </ProtectedRoute>
            <ReactQueryDevtools initialIsOpen={false} />
            <FeedbackButton />
            {import.meta.env.DEV && <AdminPanel />}
          </div>
        </Sentry.ErrorBoundary>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Calendar } from 'lucide-react';
import { formatPoints } from '../../utils/formatters';
import { AchievementIndicator } from '../AchievementIndicator/AchievementIndicator';
import { useProgressStore } from '../../stores/progressStore';

const DAY_ORDER = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface DayProgress {
  day: string;
  target: number;
  achieved: number;
}

interface DaySelectorProps {
  days: DayProgress[];
  selectedDay: string;
  onDaySelect: (day: string) => void;
}

export function DaySelector({ days, selectedDay, onDaySelect }: DaySelectorProps) {
  const dailyTotals = useProgressStore(state => state.dailyTotals);
  
  const sortedDays = React.useMemo(() => {
    const daysMap = days.reduce((acc, day) => {
      acc[day.day] = {
        ...day,
        achieved: dailyTotals[day.day] || 0 // Use real-time totals
      };
      return acc;
    }, {} as Record<string, DayProgress>);

    return DAY_ORDER.map(day => daysMap[day] || {
      day,
      target: 2000000,
      achieved: dailyTotals[day] || 0
    });
  }, [days, dailyTotals]);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 w-full lg:w-72">
      <div className="flex items-center space-x-3 mb-6">
        <Calendar className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-bold">Week Days</h2>
      </div>

      <div className="space-y-3">
        {sortedDays.map((day) => {
          const isSelected = selectedDay === day.day;
          
          return (
            <button
              key={day.day}
              onClick={() => onDaySelect(day.day)}
              className={`w-full p-4 rounded-lg transition-all duration-200 ${
                isSelected 
                  ? 'bg-blue-50 border-2 border-blue-500' 
                  : 'bg-gray-50 hover:bg-gray-100'
              }`}
            >
              <div className="flex justify-between items-center mb-2">
                <span className="font-semibold">{day.day}</span>
              </div>
              
              <AchievementIndicator 
                achieved={day.achieved}
                target={day.target}
                height="sm"
              />
              
              <div className="text-sm text-gray-600 mt-2">
                {formatPoints(day.achieved)} / {formatPoints(day.target)}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
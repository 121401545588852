import React, { useEffect, useState } from 'react';
import { Clock } from 'lucide-react';
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase-init';

export function WelcomePage() {
  const { user, isLoaded: isUserLoaded } = useUser();
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkUserSettings = async () => {
      if (!isUserLoaded) return;
      if (!user?.id) {
        setIsChecking(false);
        return;
      }
      
      try {
        console.log('Checking user settings for:', user.id);
        const userSettingsRef = doc(db, 'userSettings', user.id);
        const userSettingsSnap = await getDoc(userSettingsRef);
        
        console.log('User settings exists:', userSettingsSnap.exists());
        if (userSettingsSnap.exists()) {
          const settings = userSettingsSnap.data();
          console.log('User settings data:', settings);
          // Only navigate if we have valid player IDs
          if (settings.playerIds?.length > 0) {
            navigate('/');
          } else {
            console.warn('User settings exist but no player IDs found');
          }
        }
      } catch (error) {
        console.error('Error checking user settings:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkUserSettings();
  }, [user?.id, isUserLoaded, navigate]);

  if (!isUserLoaded || isChecking) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800 flex items-center justify-center">
        <div className="text-white">
          {!isUserLoaded ? 'Loading user...' : 'Checking account status...'}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">
            Welcome to Alliance VS Challenge
          </h1>
          <p className="text-xl text-blue-100">
            Please wait while your alliance leader sets up your account
          </p>
        </div>

        {/* Your existing cards layout */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {/* ... existing cards ... */}
        </div>

        <div className="bg-white rounded-lg shadow-xl p-8 max-w-2xl mx-auto">
          <div className="flex items-center justify-center space-x-4 mb-6">
            <Clock className="w-10 h-10 text-blue-600 animate-pulse" />
            <h2 className="text-2xl font-bold text-gray-800">Account Details</h2>
          </div>

          <div className="mb-8 p-4 bg-gray-50 rounded-lg">
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                Your User ID: <code className="bg-gray-100 px-2 py-1 rounded">{user?.id}</code>
              </p>
              <p className="text-sm text-gray-600">
                Email: <code className="bg-gray-100 px-2 py-1 rounded">{user?.emailAddresses[0]?.emailAddress}</code>
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {/* ... existing steps ... */}
          </div>

          <div className="mt-8">
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
              <h3 className="font-semibold text-blue-800 mb-2">For Alliance Leaders</h3>
              <p className="text-sm text-blue-600">
                To set up this user, use the following command in your admin panel:
              </p>
              <pre className="bg-blue-100 p-3 rounded mt-2 text-sm overflow-x-auto">
                {`createOrUpdateUserSettings("${user?.id}", {
  playerIds: ["PLAYER_ID_HERE"],
  email: "${user?.emailAddresses[0]?.emailAddress}",
  role: "user"
})`}
              </pre>
            </div>
          </div>

          <div className="mt-8 text-center">
            <p className="text-sm text-gray-500">
              Expected setup time: 5-10 minutes
            </p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Check Status
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 
import React from 'react';
import { Activity } from 'lucide-react';
import { formatPoints } from '../../utils/formatters';

interface ProgressSummaryProps {
  totalPoints: number;
  weeklyTarget: number;
}

export function ProgressSummary({ totalPoints, weeklyTarget }: ProgressSummaryProps) {
  const progress = (totalPoints / weeklyTarget) * 100;
  
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <Activity className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-bold">Weekly Progress</h2>
        </div>
        <span className="text-2xl font-bold text-blue-600">
          {Math.round(progress)}%
        </span>
      </div>

      <div className="w-full bg-gray-100 rounded-full h-4 mb-4">
        <div
          className="bg-blue-600 rounded-full h-4 transition-all duration-500"
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="flex justify-between text-sm text-gray-600">
        <span>{formatPoints(totalPoints)} earned</span>
        <span>Target: {formatPoints(weeklyTarget)}</span>
      </div>
    </div>
  );
}
import React from 'react';
import { MessageCircle, LogOut, Shield, Sword, AlertCircle } from 'lucide-react';
import { useClerk } from "@clerk/clerk-react";
import { useQuery } from '@tanstack/react-query';
import { getLeadership } from '../../services/players';

interface Warning {
  id: string;
  message: string;
  date: string;
  severity: 'high' | 'medium' | 'low';
}

interface HeaderProps {
  userName: string;
  allianceRank: string;
  warnings: Warning[];
  status: 'on-track' | 'needs-attention';
}

interface ContactLeaderModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ContactLeaderModal({ isOpen, onClose }: ContactLeaderModalProps) {
  const { data: leaders } = useQuery({
    queryKey: ['leadership'],
    queryFn: getLeadership
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 text-gray-800">
        <h3 className="text-lg font-bold mb-4">Contact Alliance Leadership</h3>
        
        <div className="space-y-4">
          <div className="bg-blue-50 p-4 rounded-lg">
            <h4 className="font-medium mb-2">How to contact leadership:</h4>
            <ol className="list-decimal list-inside space-y-2 text-sm text-gray-600">
              <li>Open alliance menu</li>
              <li>Select "Players" tab</li>
              <li>Filter by R4/R5 rank</li>
              <li>Current leadership:</li>
              <div className="pl-6 mt-1 space-y-1">
                {leaders?.map(leader => (
                  <div key={leader.id} className="flex items-center space-x-2">
                    <Shield className={`w-4 h-4 ${
                      leader.allianceRank === 'R5' ? 'text-yellow-500' : 'text-blue-500'
                    }`} />
                    <span>{leader.name} - {leader.allianceRank}</span>
                  </div>
                ))}
              </div>
            </ol>
          </div>

          <div className="bg-yellow-50 p-4 rounded-lg">
            <div className="flex items-center space-x-2 mb-2">
              <AlertCircle className="w-5 h-5 text-yellow-600" />
              <h4 className="font-medium">Important Note</h4>
            </div>
            <p className="text-sm text-gray-600">
              Please check FAQ and alliance mail before asking questions that might already be answered.
            </p>
          </div>
        </div>

        <button
          onClick={onClose}
          className="mt-6 w-full bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium py-2 px-4 rounded-lg transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export function Header({ userName, allianceRank, warnings, status }: HeaderProps) {
  const { signOut } = useClerk();
  const [showContactModal, setShowContactModal] = React.useState(false);

  return (
    <header className="bg-gradient-to-r from-gray-800 to-gray-900 text-gray-100 p-6 shadow-lg">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="flex items-center gap-2">
              <Shield className="w-8 h-8 text-yellow-500" />
              <h1 className="text-2xl font-bold">
                <span className="text-yellow-500">UKR</span>
                <span className="text-blue-500">a</span>
              </h1>
              <Sword className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h2 className="text-xl font-bold">{userName}</h2>
              <p className="text-gray-400">Alliance Rank: {allianceRank}</p>
            </div>
          </div>
          
          <div className="flex items-center space-x-6">
            {import.meta.env.DEV && (
              <div className={`px-4 py-2 rounded-full ${
                status === 'on-track' 
                  ? 'bg-green-500/20 text-green-400' 
                  : 'bg-yellow-500/20 text-yellow-400'
              }`}>
                <span className="text-sm">Dev Mode</span>
              </div>
            )}

            {/* Warnings will be enabled later */}
            {/* <WarningBadge warnings={warnings} /> */}
            
            <button 
              onClick={() => setShowContactModal(true)}
              className="flex items-center space-x-2 bg-gray-800/50 text-gray-100 px-4 py-2 rounded-lg 
                        hover:bg-gray-700/50 transition-colors border border-gray-700"
            >
              <MessageCircle className="w-5 h-5" />
              <span>Contact Leader</span>
            </button>

            <button 
              onClick={() => signOut()}
              className="flex items-center space-x-2 bg-red-500/10 text-red-400 px-4 py-2 rounded-lg 
                        hover:bg-red-500/20 transition-colors border border-red-500/20"
            >
              <LogOut className="w-5 h-5" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
        
        {import.meta.env.DEV && (
          <nav className="mt-6">
            <ul className="flex space-x-6">
              <li><a href="#progress" className="text-gray-400 hover:text-gray-100 transition-colors">My Progress</a></li>
              <li><a href="#overview" className="text-gray-400 hover:text-gray-100 transition-colors">Alliance Overview</a></li>
              <li><a href="#schedule" className="text-gray-400 hover:text-gray-100 transition-colors">Event Schedule</a></li>
            </ul>
          </nav>
        )}
      </div>

      <ContactLeaderModal 
        isOpen={showContactModal} 
        onClose={() => setShowContactModal(false)} 
      />
    </header>
  );
}
import React from 'react';
import { CheckCircle2, Circle, Target, AlertTriangle, Loader2 } from 'lucide-react';
import { formatPoints } from '../../utils/formatters';
import { useProgressStore } from '../../stores/progressStore';

interface Task {
  id: string;
  description: string;
  priority: 'critical' | 'high' | 'medium' | 'low';
  points: number;
  completed: boolean;
  category: 'event' | 'leadership' | 'defense' | 'resources' | 'development' | 'boosts' | 'preparation';
  disabled?: boolean;
}

interface TaskSection {
  section: string;
  emoji: string;
  critical?: boolean;
  tasks: Task[];
}

interface DailyTasksProps {
  day: string;
  tasks: Task[];
  onTaskToggle: (taskId: string) => void;
}

const CATEGORY_CONFIG: Record<string, { name: string; emoji: string; critical?: boolean }> = {
  event: { name: 'Event Tasks', emoji: '🎯', critical: true },
  leadership: { name: 'R4/R5 Leadership Requirements', emoji: '⚔️', critical: true },
  defense: { name: 'Defense Tasks', emoji: '🛡️' },
  resources: { name: 'Resource Tasks', emoji: '💎' },
  development: { name: 'Development Tasks', emoji: '🏗️' },
  boosts: { name: 'Boosts & Buffs', emoji: '🚀' },
  preparation: { name: 'Preparation Tasks', emoji: '📋' }
};

export function DailyTasks({ day, tasks, onTaskToggle }: DailyTasksProps) {
  const loadingTasks = useProgressStore(state => state.loadingTasks);

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'critical': return 'text-purple-600 font-bold';
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  const handleTaskToggle = (taskId: string) => {
    onTaskToggle(taskId);
  };

  // Group tasks by category
  const taskSections: TaskSection[] = React.useMemo(() => {
    const sections = Object.entries(CATEGORY_CONFIG).map(([category, config]) => ({
      section: config.name,
      emoji: config.emoji,
      critical: config.critical,
      tasks: tasks.filter(task => task.category === category)
    })).filter(section => section.tasks.length > 0);

    return sections;
  }, [tasks]);

  const totalPoints = tasks.reduce((sum, task) => 
    sum + (task.completed ? task.points : 0), 0
  );

  const targetPoints = tasks.reduce((sum, task) => sum + task.points, 0);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 flex-1">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-bold">{day}'s Tasks</h2>
        <div className="flex items-center space-x-2">
          <Target className="w-5 h-5 text-blue-600" />
          <span className="text-sm font-medium">
            {formatPoints(totalPoints)} / {formatPoints(targetPoints)} points
          </span>
        </div>
      </div>

      <div className="space-y-8">
        {taskSections.map((section) => (
          <div 
            key={section.section} 
            className={`space-y-4 ${
              section.critical 
                ? 'bg-purple-50 p-4 rounded-lg border-2 border-purple-200'
                : ''
            }`}
          >
            <div className="flex items-center space-x-2">
              <span className="text-xl">{section.emoji}</span>
              <h3 className="text-lg font-semibold text-gray-700">{section.section}</h3>
              {section.critical && (
                <AlertTriangle className="w-5 h-5 text-purple-500" />
              )}
            </div>
            
            <div className="space-y-3">
              {section.tasks.map((task) => (
                <div
                  key={task.id}
                  className={`
                    flex items-center p-4 rounded-lg transition-all duration-200 relative
                    ${task.priority === 'critical'
                      ? 'bg-purple-50 hover:bg-purple-100 border border-purple-200'
                      : 'bg-gray-50 hover:bg-gray-100'
                    }
                    ${loadingTasks[task.id] ? 'opacity-75' : 'opacity-100'}
                  `}
                >
                  <button
                    onClick={() => !task.disabled && handleTaskToggle(task.id)}
                    className="focus:outline-none mr-4 relative"
                    disabled={task.disabled || loadingTasks[task.id]}
                  >
                    {loadingTasks[task.id] ? (
                      <Loader2 className="w-6 h-6 text-blue-500 animate-spin" />
                    ) : task.completed ? (
                      <CheckCircle2 className={`w-6 h-6 ${
                        task.priority === 'critical' ? 'text-purple-500' : 'text-green-500'
                      } ${task.disabled ? 'opacity-50' : ''}`} />
                    ) : (
                      <Circle className={`w-6 h-6 text-gray-400 ${
                        task.disabled ? 'opacity-50' : ''
                      }`} />
                    )}
                  </button>

                  {loadingTasks[task.id] && (
                    <div className="absolute inset-0 bg-white/50 cursor-wait" />
                  )}

                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <span className={`text-sm font-medium ${getPriorityColor(task.priority)}`}>
                        {task.priority === 'critical' ? 'CRITICAL' : task.priority.toUpperCase()}
                      </span>
                      <span className={task.completed ? 'line-through text-gray-400' : ''}>
                        {task.description}
                      </span>
                    </div>
                    <span className="text-sm text-gray-600">
                      {formatPoints(task.points)} points
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
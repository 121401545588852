import React, { useState } from 'react';
import { useUserPlayers } from '../hooks/useUserPlayers';
import { useUser } from "@clerk/clerk-react";
import { useUserSettings } from '../hooks/useUserSettings';
import { WelcomePage } from '../components/WelcomePage';
import { Header } from '../components/Header/Header';
import { usePlayerData } from '../hooks/usePlayerData';
import { DailyTasks } from '../components/DailyTasks/DailyTasks';
import { DaySelector } from '../components/DaySelector/DaySelector';
import { ProgressSummary } from '../components/ProgressSummary/ProgressSummary';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const mockWarnings = [
  {
    id: '1',
    message: 'Launched zombies and burned whole alliance',
    date: '2024-03-15',
    severity: 'high' as const
  },
  {
    id: '2',
    message: 'Not wearing shield during war time',
    date: '2024-03-14',
    severity: 'medium' as const
  }
];

function LoadingSpinner({ message }: { message: string }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
        <p className="text-gray-600">{message}</p>
      </div>
    </div>
  );
}

function ErrorDisplay({ error }: { error: Error }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center text-red-600">
        <h2 className="text-2xl font-bold mb-2">Error</h2>
        <p>{error.message}</p>
      </div>
    </div>
  );
}

export function Home() {
  const { user, isLoaded: isUserLoaded } = useUser();
  const { 
    data: userSettings, 
    isLoading: settingsLoading,
    error: settingsError 
  } = useUserSettings();
  
  const { 
    data: players, 
    isLoading: playersLoading,
  } = useUserPlayers();

  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState(() => {
    const today = new Date().getDay();
    const dayIndex = today === 0 ? 6 : today - 1;
    return WEEKDAYS[dayIndex];
  });

  const { 
    player, 
    tasks, 
    weeklyGoals, 
    isLoading: playerDataLoading,
    handleTaskToggle 
  } = usePlayerData(selectedPlayerId);

  React.useEffect(() => {
    if (players && players.length > 0 && !selectedPlayerId) {
      setSelectedPlayerId(players[0].id);
    }
  }, [players, selectedPlayerId]);

  if (!isUserLoaded || settingsLoading) {
    return <LoadingSpinner message="Loading user data..." />;
  }

  if (settingsError) {
    return <ErrorDisplay error={settingsError} />;
  }

  if (!userSettings) {
    return <WelcomePage />;
  }

  if (playersLoading) {
    return <LoadingSpinner message="Loading players..." />;
  }

  if (!players?.length) {
    return <WelcomePage />;
  }

  const totalPoints = weeklyGoals?.reduce((sum, goal) => 
    sum + goal.achieved, 0) || 0;
  const weeklyTarget = weeklyGoals?.reduce((sum, goal) => 
    sum + goal.target, 0) || 0;

  return (
    <div className="min-h-screen bg-gray-100">
      <Header 
        userName={player?.name || ''}
        allianceRank={player?.allianceRank || ''}
        status={(player?.status || 'needs-attention') as 'on-track' | 'needs-attention'}
        warnings={mockWarnings}
      />
      
      <main className="max-w-7xl mx-auto px-4 py-8">
        <ProgressSummary 
          totalPoints={totalPoints}
          weeklyTarget={weeklyTarget}
        />
        
        <div className="flex flex-col lg:flex-row gap-8">
          <DaySelector
            days={weeklyGoals || []}
            selectedDay={selectedDay}
            onDaySelect={setSelectedDay}
          />
          
          <DailyTasks
            day={selectedDay}
            tasks={tasks?.filter(task => task.day === selectedDay) || []}
            onTaskToggle={handleTaskToggle}
          />
        </div>
      </main>
    </div>
  );
} 
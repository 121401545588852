import React from 'react';
import { MessageSquarePlus } from 'lucide-react';
import * as Sentry from "@sentry/react";
import { captureFeedback } from '../../services/sentry';

export function FeedbackButton() {
  const handleFeedback = () => {
    Sentry.showFeedbackDialog();
  };

  return (
    <button
      onClick={handleFeedback}
      className="fixed bottom-4 right-4 bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-full shadow-lg 
                transition-colors flex items-center space-x-2"
    >
      <MessageSquarePlus className="w-5 h-5" />
      <span>Report Issue</span>
    </button>
  );
} 
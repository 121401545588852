import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const COLLECTIONS = {
  PLAYERS: 'players',
  USER_SETTINGS: 'userSettings',
  TASKS: 'tasks',
  WEEKLY_GOALS: 'weeklyGoals'
} as const;

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Log that Firebase is initialized
console.log('Firebase initialized with app:', app.name);
console.log('Firestore instance:', db); 
import { create } from 'zustand';
import type { Task, WeeklyGoal } from '../types';

interface ProgressState {
  // UI State
  dailyTotals: Record<string, number>;
  weeklyTotal: number;
  weeklyTarget: number;
  isCalculating: boolean;
  loadingTasks: Record<string, boolean>;
  
  // Actions
  calculateProgress: (tasks: Task[]) => void;
  updateWeeklyGoals: (goals: WeeklyGoal[]) => void;
  updateTaskCompletion: (task: Task, completed: boolean) => void;
  setCalculating: (isCalculating: boolean) => void;
  setTaskLoading: (taskId: string, isLoading: boolean) => void;
}

export const useProgressStore = create<ProgressState>((set, get) => ({
  dailyTotals: {},
  weeklyTotal: 0,
  weeklyTarget: 0,
  isCalculating: false,
  loadingTasks: {},

  setCalculating: (isCalculating) => set({ isCalculating }),

  calculateProgress: (tasks) => {
    set({ isCalculating: true });

    try {
      const dailyTotals = tasks.reduce((acc, task) => {
        if (task.completed && task.day) {
          acc[task.day] = (acc[task.day] || 0) + task.points;
        }
        return acc;
      }, {} as Record<string, number>);

      const weeklyTotal = Object.values(dailyTotals).reduce((sum, points) => sum + points, 0);

      set({ dailyTotals, weeklyTotal });
    } finally {
      set({ isCalculating: false });
    }
  },

  updateWeeklyGoals: (goals) => {
    const weeklyTarget = goals.reduce((sum, goal) => sum + goal.target, 0);
    set({ weeklyTarget });
  },

  updateTaskCompletion: (task, completed) => {
    const { dailyTotals, weeklyTotal } = get();
    const pointDiff = completed ? task.points : -task.points;
    const currentDayTotal = dailyTotals[task.day] || 0;

    set({
      dailyTotals: {
        ...dailyTotals,
        [task.day]: Math.max(0, currentDayTotal + pointDiff)
      },
      weeklyTotal: Math.max(0, weeklyTotal + pointDiff)
    });
  },

  setTaskLoading: (taskId: string, isLoading: boolean) => 
    set(state => ({
      loadingTasks: {
        ...state.loadingTasks,
        [taskId]: isLoading
      }
    })),
})); 
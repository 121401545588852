import React from 'react';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUser } from "@clerk/clerk-react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { db, COLLECTIONS } from "../services/firebase-init";
import { useProgressStore } from '../stores/progressStore';
import type { Task, WeeklyGoal, Player } from '../types';
import { useEffectiveUser } from './useEffectiveUser';

export function usePlayerData(playerId: string | null) {
  const { id: userId } = useEffectiveUser();
  const queryClient = useQueryClient();
  const { 
    calculateProgress, 
    updateWeeklyGoals, 
    updateTaskCompletion,
    setTaskLoading 
  } = useProgressStore();

  const playerQuery = useQuery({
    queryKey: ['player', playerId],
    queryFn: async () => {
      if (!playerId) return null;
      const docRef = doc(db, COLLECTIONS.PLAYERS, playerId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
    },
    enabled: !!playerId
  });

  const tasksQuery = useQuery({
    queryKey: ['tasks', playerId],
    queryFn: async () => {
      if (!playerId) return [];
      const q = query(
        collection(db, COLLECTIONS.TASKS),
        where("playerId", "==", playerId)
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Task[];

      // Calculate progress after fetching tasks
      calculateProgress(tasks);
      return tasks;
    },
    enabled: !!playerId
  });

  const weeklyGoalsQuery = useQuery({
    queryKey: ['weeklyGoals', playerId],
    queryFn: async () => {
      if (!playerId) return [];
      const q = query(
        collection(db, COLLECTIONS.WEEKLY_GOALS),
        where("playerId", "==", playerId)
      );
      const querySnapshot = await getDocs(q);
      const goals = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as WeeklyGoal[];

      // Update weekly targets
      updateWeeklyGoals(goals);
      return goals;
    },
    enabled: !!playerId
  });

  const taskMutation = useMutation({
    mutationFn: async (taskId: string) => {
      const task = tasksQuery.data?.find(t => t.id === taskId);
      if (!task) throw new Error('Task not found');

      setTaskLoading(taskId, true);
      const newCompleted = !task.completed;
      
      try {
        // Optimistically update the UI first
        updateTaskCompletion(task, newCompleted);
        
        // Then update the server
        const taskRef = doc(db, COLLECTIONS.TASKS, taskId);
        await updateDoc(taskRef, {
          completed: newCompleted
        });

        // Update local task data immediately
        queryClient.setQueryData(['tasks', playerId], (oldData: Task[] | undefined) => 
          oldData?.map(t => t.id === taskId ? { ...t, completed: newCompleted } : t)
        );

        return { taskId, completed: newCompleted };
      } finally {
        setTaskLoading(taskId, false);
      }
    },
    onError: (error, variables) => {
      setTaskLoading(variables, false);
      // Revert optimistic update on error
      const task = tasksQuery.data?.find(t => t.id === variables);
      if (task) {
        updateTaskCompletion(task, task.completed);
      }
      // Refetch to ensure consistency
      queryClient.invalidateQueries({ queryKey: ['tasks', playerId] });
    }
  });

  return {
    player: playerQuery.data as Player | null,
    tasks: tasksQuery.data as Task[],
    weeklyGoals: weeklyGoalsQuery.data as WeeklyGoal[],
    isLoading: playerQuery.isLoading || tasksQuery.isLoading || weeklyGoalsQuery.isLoading,
    isError: playerQuery.isError || tasksQuery.isError || weeklyGoalsQuery.isError,
    error: playerQuery.error || tasksQuery.error || weeklyGoalsQuery.error,
    handleTaskToggle: (taskId: string) => taskMutation.mutate(taskId)
  };
}

import { useUser } from "@clerk/clerk-react";

export function useEffectiveUser() {
  const { user } = useUser();
  const devUserId = import.meta.env.DEV ? localStorage.getItem('dev_user_id') : null;
  
  return {
    id: devUserId || user?.id,
    isDevMode: !!devUserId,
    originalUserId: user?.id,
    devUserId
  };
} 
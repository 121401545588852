import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db, COLLECTIONS } from "../services/firebase-init";
import { useUserSettings } from "./useUserSettings";
import { useEffectiveUser } from './useEffectiveUser';
import type { UserSettings } from "../types/firebase";

export function useUserPlayers() {
  const { id: userId } = useEffectiveUser();
  const { data: userSettings, isLoading: settingsLoading } = useUserSettings();

  console.log('[useUserPlayers] Dependencies:', {
    userId,
    settingsLoading,
    hasSettings: !!userSettings,
    playerIds: userSettings?.playerIds
  });

  return useQuery({
    queryKey: ["userPlayers", userSettings?.playerIds],
    queryFn: async () => {
      if (!userSettings?.playerIds?.length) {
        console.log("[useUserPlayers] No player IDs in settings");
        return [];
      }

      console.log("[useUserPlayers] Fetching players:", userSettings.playerIds);
      
      // Fetch each player document individually since we have their IDs
      const playerPromises = userSettings.playerIds.map(playerId => 
        getDoc(doc(db, COLLECTIONS.PLAYERS, playerId))
      );
      
      const playerDocs = await Promise.all(playerPromises);
      
      const players = playerDocs
        .filter(doc => doc.exists())
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

      console.log("[useUserPlayers] Found players:", players);
      return players;
    },
    enabled: !!userId && !settingsLoading && !!userSettings?.playerIds?.length,
  });
} 
import React from 'react';
import { TrendingUp } from 'lucide-react';

interface AchievementIndicatorProps {
  achieved: number;
  target: number;
  showOverflow?: boolean;
  height?: 'sm' | 'md' | 'lg';
}

export function AchievementIndicator({ 
  achieved, 
  target, 
  showOverflow = true,
  height = 'md'
}: AchievementIndicatorProps) {
  const percentage = (achieved / target) * 100;
  
  const heightClasses = {
    sm: 'h-2',
    md: 'h-4',
    lg: 'h-6'
  };

  const getGradientStyle = () => {
    if (percentage >= 200) {
      return 'from-yellow-400 via-yellow-300 to-yellow-500';
    }
    if (percentage >= 150) {
      return 'from-purple-600 via-purple-500 to-purple-700';
    }
    return 'from-blue-600 via-blue-500 to-blue-700';
  };

  return (
    <div className="relative w-full">
      {/* Progress Stats */}
      <div className="flex justify-end mb-1">
        <div className="flex items-center space-x-2">
          {percentage > 100 && (
            <TrendingUp className="w-4 h-4 text-green-500" />
          )}
          <span className={`text-sm font-medium ${
            percentage > 100 ? 'text-green-600' : 'text-gray-600'
          }`}>
            {Math.round(percentage)}%
          </span>
        </div>
      </div>

      {/* Base progress bar */}
      <div className={`w-full bg-gray-200 rounded-full ${heightClasses[height]} overflow-hidden`}>
        <div
          className={`h-full rounded-full bg-gradient-to-r transition-all duration-500 ${getGradientStyle()}`}
          style={{ 
            width: `${Math.min(100, percentage)}%`,
          }}
        />
      </div>

      {/* Achievement Indicators */}
      <div className="flex justify-end mt-1">
        {percentage >= 150 && (
          <span className="text-4xl">
            {percentage >= 200 ? '🍍' : '🔥'}
          </span>
        )}
      </div>
    </div>
  );
} 